import classes from "./SecondBlog.module.css";
// import bookAppointment from "./about1.png";
import image from "./surgery3.jpg";

function FirstBlock() {
  return (
    <div className={classes.LeftImageRightTextParent}>
      <div className={classes.websiteContent2}>
        <div className={classes.detailContainer2}>
          {/* <div className={classes.aboutHeading1}>Empowerment</div> */}

          <div className={classes.aboutHeading2}>
          Advancements in Heart Transplant Surgery:
          </div>

          <div className={classes.aboutDetails}>
          Heart transplant surgery remains the gold standard treatment for end-stage heart failure, offering a life-saving option for patients with advanced cardiac disease. Recent advancements in heart transplant surgery have focused on improving organ preservation techniques, optimizing immunosuppressive therapies, and enhancing surgical techniques to minimize complications and improve outcomes. Dr. Baryon Swain specializes in heart transplant surgery, providing comprehensive care to patients throughout the transplant process, from the evaluation and selection of transplant candidates to post-transplant care and management. With his expertise and dedication to excellence, Dr. Swain continues to push the boundaries of heart transplant surgery, offering hope and healing to patients in need of a new lease on life.
          </div>
        </div>

        <div className={classes.imgContainer2}> 
        <img
          className={classes.HomeImageContainer2}
          src={image}
          alt="logo"
        ></img>
        </div>
      </div>
    </div>
  );
}

export default FirstBlock;
