
import classes from './DocumentNames.module.css';





const DocButton=(props)=>{


  const selectDocHandler=()=>{

    let bylawId = props.doc.id;	 

    props.selectADocumentHandler(bylawId);	  

  }

   let color = props.byLawId === props.doc.id? "green": "black";



   return  <button type="button" className={classes.docnameButton} onClick={selectDocHandler} style={{color:color,borderColor:color}}> {props.doc.name}  </button>

}

export default DocButton;
