// import { useState } from "react";
import React, { useState, useEffect } from "react";

import classes from "./Website.module.css";
import { Route, Switch, useHistory } from "react-router-dom";
import Header from "./Header";
import HeaderAddressBar from "./HeaderAddressBar";
import Footer from "./Footer";
import Home from "./Home/Home";
import About from "./About/About";
import News from "./News/News";
import ContactUs from "./Contactus/ContactUs";

import HomeBuyer from "./ClientPage1/HomeBuyer";
import Notices from "./ClientPage1/Notices";
import RulesAndRegulations from "./ClientPage1/RulesAndRegulations";
import Blogs from "./Home/BlogAndNewsBlock";

import Projects from "./ClientPage1/Projects/Projects";
import Booking from "./ClientPage1/Booking";
import SaleDeed from "./ClientPage1/SaleDeed";
import Possession from "./ClientPage1/Posession";
import Association from "./ClientPage1/Association";
import Maintenance from "./ClientPage1/Maintenance";

import IntroductionBlock from "./Home/IntroductionBlock";

import ReraAct from "./ClientPage1/ReraAct";

// import bg from "./bg.png"

// import React, { useState } from 'react';

// Replace these with your image URLs

const Website = () => {
  const images = [
    //"https://cdn.pixabay.com/photo/2020/03/31/11/06/crowd-4987226_1280.jpg",

    //"https://media.istockphoto.com/id/625736338/photo/stack-of-hands-showing-unity.jpg?s=2048x2048&w=is&k=20&c=m0q5TaMHzRZEzojxhGIFsFMCwWdHdkFwqtAAi1Bf67Q=",
     //"https://img.freepik.com/free-photo/people-having-protest-world-environment-day_23-2149358272.jpg?w=1380&t=st=1697119066~exp=1697119666~hmac=5c62aa46c7652ee03b27caad210bb5fb167d2d58d495049b5495b1b0185b0759",
    //"https://images.unsplash.com/photo-1585822732780-a433cd6368ad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80",
    //"https://images.unsplash.com/photo-1604223190546-a43e4c7f29d7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const registerHandler = () => {
    window.open("createaccount", "_blank");
  };

  const loginHandler = () => {
    window.open("app/dashboard/general/exams", "_blank");
  };

  const history = useHistory();

  //const [showHome, setShowHome] = useState(true);
  //const [showAbout, setShowAbout] = useState(false);
  //const [showNews, setShowNews] = useState(false);
  // const [showHomeBuyer, setShowHomeBuyer] = useState(false);
  // const [showContactUs, setShowContactUs] = useState(false);

  const [homeMounted, setHomeMounted] = useState(false);
  const [aboutMounted, setAboutMounted] = useState(false);
  const [newsMounted, setNewsMounted] = useState(false);
  const [homeBuyerMounted, setHomeBuyerMounted] = useState(false);
  const [actsMounted, setActsMounted] = useState(false);
  const [contactUsMounted, setContactUsMounted] = useState(false);


  const [noticesMounted, setNoticesMounted] = useState(false);
  const [rulesAndRegulationsMounted, setRulesAndRegulationsMounted] = useState(false);
  const [blogsMounted, setBlogsMounted] = useState(false);
  const [projectsMounted, setProjectsMounted] = useState(false);
  const [bookingMounted, setBookingMounted] = useState(false);
  const [saleDeedMounted, setSaleDeedMounted] = useState(false);
  const [possessionMounted, setPossessionMounted] = useState(false);
  const [associationMounted, setAssociationMounted] = useState(false);
  const [maintenanceMounted, setMaintenanceMounted] = useState(false);
  const [reraactMounted, setReraActMounted] = useState(false);



  const homeBuyerNoticeMountHandler = () => {
    setHomeBuyerMounted(true);
    setNoticesMounted(true);
  };

  const homeHandler = () => {
    history.push("/");
  };

  const aboutHandler = () => {
    history.push("/about");
  };

  const newsHandler = () => {
    history.push("/blog");
  };

  const homeBuyerHandler = () => {};


  const actsHandler=()=>{
    history.push("/treatmentOptions");
  }


  const contactUsHandler = () => {
    history.push("/contactus");
  };

  const noticesHandler = () => {
    history.push("/homebuyer/notices");
  };

  const isAnyVariableTrue = (...variables) => {
    return variables.some((variable) => !!variable);
  };

  //console.log("notice mounted: ", noticesMounted);

  return (
    <div className={classes.website}>
      <HeaderAddressBar />
      {homeMounted && (
        <div className={classes.backgroundImage}>
          <div className="carousel">
            <div
              className="carousel-content"
              style={{ backgroundImage: `url(${images[currentIndex]})` }}
            >
              <Header
                homeHandler={homeHandler}
                homeMounted={homeMounted}
                aboutHandler={aboutHandler}
                aboutMounted={aboutMounted}
                newsHandler={newsHandler}
                newsMounted={newsMounted}
                aboutMounted={aboutMounted}
                homeBuyerHandler={homeBuyerHandler}
                homeBuyerMounted={isAnyVariableTrue(
                  noticesMounted,
                  rulesAndRegulationsMounted,
                  reraactMounted,
                  blogsMounted,
                  projectsMounted,
                  bookingMounted,
                  saleDeedMounted,
                  possessionMounted,
                  associationMounted,
                  maintenanceMounted
                )}
                noticesMounted={noticesMounted}
                contactUsHandler={contactUsHandler}
                contactUsMounted={contactUsMounted}
              />

              <div className={classes.mainContainer}>
                {/* <button onClick={prevSlide} className="arrow left-arrow">
              &#9665;
            </button> */}

                <IntroductionBlock />

                {/* <button onClick={nextSlide} className="arrow right-arrow">
              &#9655;
            </button> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {!homeMounted && (
        <Header
          homeHandler={homeHandler}
          homeMounted={homeMounted}
          aboutHandler={aboutHandler}
          aboutMounted={aboutMounted}
          newsHandler={newsHandler}
          newsMounted={newsMounted}
          aboutMounted={aboutMounted}
          homeBuyerHandler={homeBuyerHandler}
          homeBuyerMounted={isAnyVariableTrue(
            noticesMounted,
            rulesAndRegulationsMounted,
            reraactMounted,
            blogsMounted,
            projectsMounted,
            bookingMounted,
            saleDeedMounted,
            possessionMounted,
            associationMounted,
            maintenanceMounted
          )}
          noticesMounted={noticesMounted}
          actsMounted ={actsMounted}
	  actsHandler={actsHandler} 
          contactUsHandler={contactUsHandler}
          contactUsMounted={contactUsMounted}
        />
      )}

      <Route exact path="/">
        <Home passMountInfo={setHomeMounted} />
      </Route>

      <div className={classes.innerDiv}>
        <Switch>
          <Route exact path="/blog">
            <News passMountInfo={setNewsMounted} />
          </Route>

          <Route exact path="/about">
            <About passMountInfo={setAboutMounted} />
          </Route>

          <Route exact path="/treatmentOptions">
            <RulesAndRegulations
              passMountInfo={setActsMounted}
            />
          </Route>


          <Route exact path="/contactus">
            <ContactUs passMountInfo={setContactUsMounted} />
          </Route>

	  {/*
          <Route exact path="/homebuyer/notices">
            <Notices passMountInfo={setNoticesMounted} />
          </Route>


          <Route exact path="/actsss">
            <ReraAct passMountInfo={setReraActMounted} />
          </Route>

          <Route exact path="/homebuyer/blogs">
            <Blogs passMountInfo={setBlogsMounted} />
          </Route>

          <Route exact path="/homebuyer/lifecycle/projects">
            <Projects passMountInfo={setProjectsMounted} />
          </Route>

          <Route exact path="/homebuyer/lifecycle/booking">
            <Booking passMountInfo={setBookingMounted} />
          </Route>

          <Route exact path="/homebuyer/lifecycle/saledeed">
            <SaleDeed passMountInfo={setSaleDeedMounted} />
          </Route>

          <Route exact path="/homebuyer/lifecycle/possession">
            <Possession passMountInfo={setPossessionMounted} />
          </Route>

          <Route exact path="/homebuyer/lifecycle/association">
            <Association passMountInfo={setAssociationMounted} />
          </Route>

          <Route exact path="/homebuyer/lifecycle/maintenance">
            <Maintenance passMountInfo={setMaintenanceMounted} />
          </Route>

         */}

        </Switch>
      </div>

      <Footer />
    </div>
  );
};

export default Website;
