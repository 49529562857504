import { useEffect, useState, useRef } from "react";

import BlogAndNewsBlock from "./BlogAndNewsBlock";
import OneFriend from "../../MainApps/Account/UserProfile/Friends/OneFriend";
import OneNewsBlock from "./OneNewsBlock";
import classes from "./News.module.css";

import imageNews1 from "./A11.png";
import imageNews2 from "./A2 .jpg";
import imageNews3 from "./nw3.png";
import imageNews4 from "./nw4.jpg";


import image from "./surgery1.jpg";

import image3 from "./surgery2.jpg";

import image2 from "./surgery3.jpg";


import SecondBlock from "./SecondBlog";
import FirstBlock from "./firstBlog";
import ThirdBlock from "./ThirdBlog";
import FourthBlock from "./FourthBlog";
import FifthBlock from "./FifthBlog";

const News = (props) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);


  return (
    <div className={classes.parentContainer}>

      <FirstBlock />
      <SecondBlock />
      <ThirdBlock />
      <FourthBlock />
      <FifthBlock />


   
    </div>
  );
};

export default News;
