


import classes from './DocumentNames.module.css';

import DocButton from './DocButton';


const DocumentNames=(props)=>{




return <div className={classes.DocumentNames}>


       <div style={{marginBottom:"10px"}}> All your docs </div>


       { props.allDocs.map((doc,index)=>{


            return <DocButton  key={index} doc={doc} selectADocumentHandler={props.selectADocumentHandler} byLawId={props.byLawId}/>


           })

       }




</div>

}

export default DocumentNames;
