import classes from "./Footer.module.css";

import { MdOutlineWatchLater } from "react-icons/md";

import { AiOutlineMail } from "react-icons/ai";

import {
  MdCall,
  MdAccessTimeFilled,
  MdEmail,
  MdLocationPin,
} from "react-icons/md";

import {
  BsFacebook,
  BsTwitter,
  BsLinkedin,
  BsYoutube,
  BsEnvelope,
} from "react-icons/bs";

import { useHistory } from "react-router-dom";

function Footer() {
  const redirectDiracAIHandler = () => {
    window.open("https://diracai.com/services/", "_blank");
  };

  const history = useHistory();

  const homePageHandler = () => {
    history.push("/");
    window.scrollTo(0, 0); 
  };

  const aboutPageHandler = () => {
    history.push("/about");
    window.scrollTo(0, 0); 
    
  };

  const newsHandler = () => {
    history.push("/Blog");
    window.scrollTo(0, 0); 
  };

  const contactusHandler = () => {
    history.push("/contactus");
    window.scrollTo(0, 0); 
  };


   const twitterHandler=()=>{

  //  window.open("https://twitter.com/bpradhanodisha?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor","_blank"); 

   }


   const treatmentOptionsHandler = () => {
    history.push("/treatmentoptions");
    window.scrollTo(0, 0); 
  };







  return (
    <div className={classes.footer}>
      <div className={classes.mainFooterContainer}>
        <div className={classes.block1}>
          <div className={classes.logotextContainer}>
            <div className={classes.logopic}>
              <div className={classes.csslogo}>
                <span>Dr</span>
	         {/*
                <div className={classes.styleDiv1}> </div>
		*/}
              </div>
            </div>
            <div className={classes.websiteNametext}>Baryon Swain</div>
          </div>

          <div className={classes.websiteDetail}>
          Dr. Baryon Swain is a highly esteemed Cardiothoracic and Vascular Surgeon with
           a track record of excellence and accomplishment in the field of healthcare
          </div>

          {/* <div className={classes.cont4}>
            <MdAccessTimeFilled className={classes.cont4Icon} />

            <div className={classes.cont4textContainer}>
              <div className={classes.cont4Title1}>Monday - Saturday</div>
              <div className={classes.cont4Title2}>9:00am - 10:00Pm</div>
            </div>
          </div> */}
        </div>

        <div className={classes.block2}>
          <div className={classes.otherLink}>Other Links</div>
          {/*onClick={homePageHandler}*/}
          <button className={classes.cont1} >
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={homePageHandler}>Home</div>
          </button>

	  {/*onClick={aboutPageHandler}*/}
          <button className={classes.cont1} >
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={aboutPageHandler}>About Us</div>
          </button>

	  {/*onClick={newsHandler}*/}
          {/* <button className={classes.cont1} >
            <div className={classes.cont1Icon}></div>
            <div className={classes.cont1Text}>News</div>
          </button> */}

          <button className={classes.cont1} >
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={newsHandler}>Blog</div>
          </button> 

          
	  {/*onClick={contactusHandler}*/}
          <button className={classes.cont1} >
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={contactusHandler}>Contact Us</div>
          </button>
        </div>

        <div className={classes.block3}>
          <div className={classes.otherLink}>Services</div>

          <div className={classes.cont2}>
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={treatmentOptionsHandler}>Minimally Invasive Cardiac Surgery
</div>
          </div>

          <div className={classes.cont2}>
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={treatmentOptionsHandler} >Vascular and Endovascular Repair</div>
          </div>

          <div className={classes.cont2}>
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={treatmentOptionsHandler}>
            Heart Transplant Surgery
            </div>
          </div>

          <div className={classes.cont2}>
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={treatmentOptionsHandler}>Thoracic Procedures
</div>
          </div>

          <div className={classes.cont2}>
            {/* <div className={classes.cont1Icon}></div> */}
            <div className={classes.cont1Text} onClick={treatmentOptionsHandler}>
            Hypertrophic Cardiomyopathy Surgery:

            </div>
          </div>
        </div>
        <div className={classes.block4}>
          <div className={classes.otherLink}>Contact Us</div>

          <div className={classes.cont4} onClick={twitterHandler}>
            <BsTwitter className={classes.cont4Icon} />

            <div className={classes.cont4textContainer}>
              <div className={classes.cont4Title1}>Twitter</div>
              {/* <div className={classes.cont4Title2}>
                Bipul Green Opposite to Aims, Bhubhneshwar
              </div> */}
            </div>
          </div>

          <div className={classes.cont4}>
            <MdEmail className={classes.cont4Icon} />

            <div className={classes.cont4textContainer}>
              <div className={classes.cont4Title1}>
                  drbaryonswain@gmail.com
              </div>
              {/* <div className={classes.cont4Title2}>help@examples.com</div> */}
            </div>
          </div>

          <div className={classes.cont44}>
            <MdCall className={classes.cont4Icon} />

            <div className={classes.cont4textContainer}>
              <div className={classes.cont4Title1}>+ 91 xx-xx-xx-xx-xx</div>
              {/* <div className={classes.cont4Title2}>+ 91 977745590</div> */}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.bottomBar}>
        <div className={classes.horiLine}></div>

        <div className={classes.text1}>
          Copyright 2023 © drbaryonswain.com All rights reserved.
        </div>

        <div className={classes.cmpDetail}>
          <div className={classes.powerBy}>Powered By</div>

          <button
            className={classes.footerLogoBtn}
            onClick={redirectDiracAIHandler}
          >
            <div className={classes.logo}>Di</div>

            <div
              className={classes.websiteDetails}
              onClick={redirectDiracAIHandler}
            >
              DiracAI Services
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
