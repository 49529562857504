import OneNewsBlock from "../News/OneNewsBlock";
import classes from "./FirstBlock.module.css";
import bookAppointment from "./about1.png";
import image from "./surgery3.jpg";




import imageNews1 from "./imgOneInFour.jpg";
import imageNews2 from "./imgThreeInFour.jpg";
import imageNews3 from "./ImgTwoInFour.jpg";
import imageNews4 from "./imgFourInFour.jpg"; 
import News from "../News/News";

function FirstBlock() {
  let title1 =
  " Minimally Invasive Cardiac Surgery: ";
let description1 =
  "Traditionally, cardiac surgery often required large incisions and prolonged recovery times. However, advancements in minimally invasive techniques have revolutionized the field, offering patients less invasive treatment options with reduced pain, shorter hospital stays, and faster recovery. Minimally invasive cardiac surgery encompasses a range of procedures, including coronary artery bypass grafting (CABG), valve repair and replacement, atrial fibrillation surgery, and cardiac tumor removal. By utilizing smaller incisions, specialized instruments, and advanced imaging technology, surgeons can perform complex cardiac procedures with precision and accuracy, leading to improved patient outcomes and enhanced quality of life.";
let link1 =
  "https://www.newindianexpress.com/cities/bhubaneswar/2023/feb/24/comply-with-rera-norms-withintwo-monthsorera-to-bhubaneswar-development-authority-2550482.html";

let title2 = " Hybrid Procedures for Aortic Aneurysms:";

let description2 =
  " Aortic aneurysms are potentially life-threatening conditions that require prompt treatment to prevent rupture or dissection. Hybrid procedures, combining elements of traditional open surgery with minimally invasive endovascular techniques, have emerged as an innovative treatment approach for aortic aneurysms. In a hybrid procedure, the surgeon performs a combination of open surgical repair and endovascular stent graft placement, tailored to the specific anatomy and characteristics of the aneurysm. This hybrid approach offers patients the benefits of both procedures, including reduced surgical trauma, shorter recovery times, and improved long-term outcomes. Dr. Baryon Swain, a leading expert in Cardiothoracic and Vascular Surgery, has been at the forefront of developing and refining hybrid procedures for aortic aneurysms, demonstrating his commitment to innovation and excellence in patient care.";
let link2 =
  "https://timesofindia.indiatimes.com/city/cuttack/orissa-hc-upholds-ban-on-registering-house-sale-deeds/articleshow/94496807.cms";


let title3 = "Advancements in Heart Transplant Surgery:";

let description3 = "Heart transplant surgery remains the gold standard treatment for end-stage heart failure, offering a life-saving option for patients with advanced cardiac disease. Recent advancements in heart transplant surgery have focused on improving organ preservation techniques, optimizing immunosuppressive therapies, and enhancing surgical techniques to minimize complications and improve outcomes. Dr. Baryon Swain specializes in heart transplant surgery, providing comprehensive care to patients throughout the transplant process, from the evaluation and selection of transplant candidates to post-transplant care and management. With his expertise and dedication to excellence, Dr. Swain continues to push the boundaries of heart transplant surgery, offering hope and healing to patients in need of a new lease on life.";

let title4 = "Innovative Treatments for Hypertrophic Cardiomyopathy:";

let description4 = "Hypertrophic cardiomyopathy (HCM) is a genetic condition characterized by abnormal thickening of the heart muscle, leading to symptoms such as chest pain, shortness of breath, and arrhythmias. Dr. Baryon Swain specializes in innovative treatments for HCM, including septal myectomy, alcohol septal ablation, and implantation of pacemakers or defibrillators. These treatments aims to alleviate symptoms, improve cardiac function, and reduce the risk of complications in patients with HCM. Dr. Swain's expertise in the management of HCM underscores his commitment to providing personalized, patient-centered care and improving outcomes for individuals living with this challenging condition.";

  return (
    <div className={classes.LeftImageRightTextParent}>

      <div className={classes.blogTitle}>BLOG</div>
      <div className={classes.websiteContent2}>
      <div className={classes.newsBlock}>

          
            <div className={classes.natitonalNewsBlock}>
              {/* <div className={classes.National}>National</div> */}
              <OneNewsBlock
                title={title1}
                description={description1}
                link={link1}
                image={imageNews1}
              />
            </div>

            <div className={classes.stateNewsBlock}>
              {/* <div className={classes.State}>State</div> */}

              <OneNewsBlock
                title={title2}
                description={description2}
                // link={link2}
                image={imageNews2}
              />
            </div>
            </div> 

            
      </div>
    </div>
  );
}

export default FirstBlock;
