import classes from "./Header.module.css";

import {
  BsFacebook,
  BsTwitter,
  BsLinkedin,
  BsYoutube,
  BsEnvelope,
} from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";

import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";


import {useHistory} from 'react-router-dom';



const HeaderAddressBar = () => {


  const history = useHistory();


  const faceBookHandler = () => {
    window.open(
      "https://twitter.com/bpradhanodisha?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      "_blank"
    );
  };

  const xHandler = () => {
    window.open(
      "https://twitter.com/bpradhanodisha?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
      "_blank"
    );
  };

  const youTubeHandler = () => {
    window.open(
      "https://youtube.com/@BimalenduPradhan-bb1xl?si=FHWvYGAqciY550KV",
      "_blank"
    );
  };

  const linkedinHandler = () => {
    window.open(
      "https://www.linkedin.com/in/bimalendu-pradhan-b75365a/",
      "_blank"
    );
  };


  

  


   const loginHandler=()=>{

    history.push('/app/home/tools'); 

 


   }





  return (
    <div className={classes.topbar}>
      <div className={classes.inner_topbar}>
        <div className={classes.rightContainer}>
          <div className={classes.firstTopContainer}>
            <div className={classes.emailIcon}>
              <BsEnvelope size={20} />
            </div>
            <div className={classes.textEmail}>
               drbaryonswain@gmail.com
            </div>
          </div>

          {/*
               <div className={classes.secTopContainer}>
                  <div className={classes.mobileIcon}></div>
                  <div className={classes.mobileNumber}>+91 929399494959</div>
               </div>
	       */}
        </div>

        <div className={classes.socialiconContainer}>
          <button className={classes.fbicon} >
            <FaFacebookF size={20} />
          </button>
          <button className={classes.fbicon} >
            <BsTwitter size={20} />
          </button>
          <button className={classes.fbicon} >
            <FaLinkedinIn size={20} />
          </button>
          <button className={classes.fbicon} >
            <BsYoutube size={25} />
          </button>
         
	  {/*
         <button type="button" className={classes.loginButton} onClick={loginHandler}>
            Login
         </button>
          */}
        </div>
      </div>
    </div>
  );
};

export default HeaderAddressBar;
