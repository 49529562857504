import PreAppSecond from './PreAppSecond';
import {Route,Switch} from 'react-router-dom'
import Register from './CommonApps/Register';
import RegisterV2 from './CommonApps/Akshay/Register';
import Website from './WebSite/Website';


const PreAppFirst=()=>{



return (

<div>

<Switch>

   <Route exact path='/createaccount/' >
        <RegisterV2/>
   </Route>

  <Route  path='/app' >
        <PreAppSecond/>
   </Route>


  
   <Route   path='/' >
        <Website/>
   </Route>
  
   



</Switch>




</div>
);
}

export default PreAppFirst;
