import classes from "./ThirsBlog.module.css";
// import bookAppointment from "./about1.png";
import image from "./surgery2.jpg";

function SecondBlock() {
  return (
    <div className={classes.LeftImageRightTextParent}>
      <div className={classes.websiteContent2}>
        <img
          className={classes.HomeImageContainer2}
          src={image}
          alt="logo"
        ></img>

        <div className={classes.detailContainer2}>
          {/* <div className={classes.aboutHeading1}>Empowerment</div> */}

          <div className={classes.aboutHeading2}>

          Innovative Treatments for Hypertrophic Cardiomyopathy:
          </div>

          <div className={classes.aboutDetails}>
       
          Hypertrophic cardiomyopathy (HCM) is a genetic condition characterized by abnormal thickening of the heart muscle, leading to symptoms such as chest pain, shortness of breath, and arrhythmias. Dr. Baryon Swain specializes in innovative treatments for HCM, including septal myectomy, alcohol septal ablation, and implantation of pacemakers or 
          defibrillators. These treatments aims to alleviate symptoms, improve cardiac function, and reduce the risk of complications in 
          patients with HCM.
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondBlock;
