import {useState} from 'react';
import classes from './createdocForm.module.css';
import {AiFillCloseCircle} from 'react-icons/ai';
import {OptionField, OptionFieldSubmitValue, OptionFieldSecondaryObjs, ParagraphField,TextInput, DateField} from '../../../CommonApps/FormInputObjects';

import {createbylaw} from '../../../CommonApps/AllAPICalls';





const CreatedocForm=(props)=>{


     const [classRank, setClassRank] = useState([{"id": 1,"name": "Blanck Document"}, {"id": 2,"name": "Standard-ORERA-ByLaw"}]);

     const initialFormData = Object.freeze({         
          name: "",
          description: "",
     });

     const [formData, updateFormData] = useState(initialFormData);


    const [ createState, setCreateState] = useState("notCreating");

     const handleChange=(e)=>{

       updateFormData({
                        ...formData,
                        [e.target.name]: e.target.value.trim(),
                });

     }


     const handleChangeContent=()=>{



     }


     const createDocHandler=()=>{
       setCreateState(createState=>"Creating");
       createbylaw({formData,setCreateState, props});

     }



    console.log("formData: ", formData);






return <div className={classes.createdocForm}>





    <div className={classes.createFormInner}>

        <div className={classes.closeButtonDiv}>
            <button onClick={props.onPress} className={classes.closeFormButton}> <AiFillCloseCircle className={classes.closeButtonIcon}/> </button>
        </div>


	<div className={classes.createFormDiv}>	

            <TextInput handleChange={handleChange}
                    label="Name of the document"
                    placeholder="e.g. Trident Resident Welfare Association By Law"
                    name="name"
                    requirement="*"
	            defaultValue=""
                    />

            <OptionField handleChange={handleChange}  
	            label="Content" 
	            name="contentId"  
	            options={classRank}
	            requirement="*"
		    />


	   { createState === "notCreating" &&	
	    <div className={classes.buttonDiv}>	
                <button type="button" className={classes.createButton} onClick={createDocHandler}> Create </button>
            </div>
	   }


           { createState === "Creating" &&
            <div className={classes.buttonDiv}>
                <button type="button" className={classes.createButton} onClick={createDocHandler} disabled={true}> Creating... </button>
            </div>
           }


           { createState === "Created" &&
            <div className={classes.buttonDiv}>
                <button type="button" className={classes.createButton} onClick={createDocHandler} disabled={true}> Created </button>
            </div>
           }






        </div>



    </div>

</div>


}


export default CreatedocForm;
