import { useEffect, useState, useRef } from "react";

import classes from "./Home.module.css";
import IntroductionBlock from "./IntroductionBlock";
import CenterColumnLeftRightGrid from "./CenterColumnLeftRightGrid";
import Testimonial from "./Testimonial";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import TeamBlock from "./TeamBlock";
import Appointment from "./Apointment";
import BlogAndNewsBlock from "./BlogAndNewsBlock";
import LeftImageRightText from "./LeftImageRightText";
import OneNewsBlock from "../News/OneNewsBlock";

import innerDiv from "../Website.module.css";

import FirstBlock from "./FirstBlock";
import SecBlock from "./SecondBlock";

import imageNews1 from "../News/A11.png";
import imageNews2 from "../News/A2 .jpg";

let title1 =
  "Comply with RERA norms within two months: ORERA to Bhubaneswar Development Authority";
let description1 =
  "RERA activist Bimalendu Pradhan who had moved ORERA in this regard said that the order of the regulatory authority must serve as reminder for the promoters and developers.BHUBANESWAR: The multi-crore Daya Enclave project of the Bhubaneswar Development Authority (BDA) seems to have landed in trouble again as the Odisha Real Estate Regulatory Authority (ORERA) has asked the agency to comply with the norms of the RERA act within two months...";
let link1 =
  "https://www.newindianexpress.com/cities/bhubaneswar/2023/feb/24/comply-with-rera-norms-withintwo-monthsorera-to-bhubaneswar-development-authority-2550482.html";

let title2 = "Orissa HC lens on ORERA 'skirting' orders in realty disputes";

let description2 =
  " The Orissa high court on Tuesday reaffirmed that the ban imposed by it on registration of sale deeds related to apartments and flats will continue till the provisions of law are scrupulously followed in terms of the orders already passed by the court.  The HC was hearing a PIL challenging the validity of Odisha Apartment Ownership (Amendment) Rules-2021 on the ground that it is contrary to provisions of the RERA Act-2016, which mandates...";
let link2 =
  "https://timesofindia.indiatimes.com/city/cuttack/orissa-hc-upholds-ban-on-registering-house-sale-deeds/articleshow/94496807.cms";

function Home(props) {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);

  return (
    <div className={classes.homeParent}>
      {/*
      <div className={classes.panImageBkg}>
        <IntroductionBlock />
      </div>
      */}
      <div className={innerDiv.innerDiv}>
 
        
        <CenterColumnLeftRightGrid />
        
        {/* <TeamBlock /> */}
        {/* <Appointment /> */}

         <LeftImageRightText />
        <FirstBlock />
        <SecBlock /> 
        
	  
        <Testimonial />
          


        {/* <div className={classes.latest}>
          <div className={classes.latestTitle}>NEWS</div>
          <div className={classes.latestsubTitle}>In News</div> 

<div className={classes.topBar}>
            <div className={classes.National}>National</div>
            <div className={classes.State}>State</div>
          </div>
        {/* </div>

        <div className={classes.newsBlock}>

          
          <div className={classes.natitonalNewsBlock}>
            <div className={classes.National}>National</div>
            <OneNewsBlock
              title={title1}
              description={description1}
              link={link1}
              image={imageNews1}
            />
          </div>

          <div className={classes.stateNewsBlock}>
            <div className={classes.State}>State</div>

            <OneNewsBlock
              title={title2}
              description={description2}
              link={link2}
              image={imageNews2}
            />
          </div>
        </div> */}

	  {/*
        <BlogAndNewsBlock />
         <FAQ /> */}
      </div>

         <div className={classes.belowFooter}>
          <div className={classes.contentTxt}>
            <div className={classes.contentTxtTitle}>Ready to prioritize your heart health? Schedule a consultation with Dr. Baryon Swain today.<br />
Your journey to a healthier heart starts here.</div>
            <div className={classes.btnTxtContainer}> <button className={classes.btnTxt}>Book Your Appointment Now</button></div>
          </div>
         </div>
    </div>
  );
}

export default Home;
