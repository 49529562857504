import classes from "./FifthBlog.module.css";
// import bookAppointment from "./about1.png";
import image from "./surgery1.jpg";

function LeftImageRightText() {
  return (
    <div className={classes.LeftImageRightTextParent}>
      
      
      
      <div className={classes.websiteContent2}>
        <img
          className={classes.HomeImageContainer2}
          src={image}
          alt="logo"
        ></img>

        <div className={classes.detailContainer2}>
          {/* <div className={classes.aboutHeading1}>Empowerment</div> */}

          <div className={classes.aboutHeading2}>
          "Protecting Your Heart: Key Strategies for Preventing Heart Disease"
          </div>

          <div className={classes.aboutDetails}>
          Heart disease remains the leading cause of death worldwide, but the good news is that many cases are preventable
           through lifestyle modifications and proactive healthcare measures. 
          In this blog post, we'll explore some key strategies for preventing heart disease and maintaining heart health for a lifetime.
          <ul>
            <li>Eat a Heart-Healthy Diet</li>
            <li>Stay Active</li>
            <li>Maintain a Healthy Weight</li>
            <li>Manage Stress</li>
            <li>Quit Smoking</li>
            <li>Limit Alcohol Consumption</li>
            <li>Get Regular Check-ups</li>
            <li>Know Your Family History</li>
          </ul>
          </div>
        </div>
      </div>

    </div>
  );
}

export default LeftImageRightText;
