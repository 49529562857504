import classes from "./firstBlog.module.css";
// import bookAppointment from "./about1.png";
import image from "./surgery1.jpg";

function LeftImageRightText() {
  return (
    <div className={classes.LeftImageRightTextParent}>
      
      <div className={classes.titleBlog}>BLOG</div>
      
      <div className={classes.websiteContent2}>
        <img
          className={classes.HomeImageContainer2}
          src={image}
          alt="logo"
        ></img>

        <div className={classes.detailContainer2}>
          {/* <div className={classes.aboutHeading1}>Empowerment</div> */}

          <div className={classes.aboutHeading2}>
          Advancements in Cardiothoracic and Vascular Surgery-
                    Minimally Invasive Cardiac Surgery:
          </div>

          <div className={classes.aboutDetails}>
          Traditionally, cardiac surgery often required large incisions and prolonged recovery times.
           However, advancements in minimally invasive techniques have revolutionized the field, offering patients less
            invasive treatment options with reduced pain, shorter hospital stays, and faster recovery. Minimally invasive 
            cardiac surgery encompasses a range of procedures, including coronary artery bypass grafting (CABG), valve repair and replacement, 
            atrial fibrillation surgery, and cardiac tumor removal. By utilizing smaller incisions, specialized instruments, and advanced imaging technology, 
          surgeons can perform complex cardiac procedures with precision and accuracy,
           leading to improved patient outcomes and enhanced quality of life.
          </div>
        </div>
      </div>

    </div>
  );
}

export default LeftImageRightText;
