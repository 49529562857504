import classes from "./FourthBlog.module.css";
// import bookAppointment from "./about1.png";
import image from "./ImgTwoInFour.jpg";

function FirstBlock() {
  return (
    <div className={classes.LeftImageRightTextParent}>
      <div className={classes.websiteContent2}>
        <div className={classes.detailContainer2}>
          {/* <div className={classes.aboutHeading1}>Empowerment</div> */}

          <div className={classes.aboutHeading2}>
          Advancements in Cardiothoracic and Vascular Surgery:Hybrid Procedures for Aortic Aneurysms:
          </div>

          <div className={classes.aboutDetails}>
          Aortic aneurysms are potentially life-threatening conditions that require 
          prompt treatment to prevent rupture or dissection. Hybrid procedures, combining 
          elements of traditional open surgery with minimally invasive endovascular techniques, 
          have emerged as an innovative treatment approach for aortic aneurysms. In a hybrid procedure, 
          the surgeon performs a combination of open surgical repair and endovascular stent graft placement, 
          tailored to the specific anatomy and characteristics of the aneurysm. This hybrid approach offers patients 
          the benefits of both procedures, including reduced surgical trauma, shorter recovery times, and improved 
          long-term outcomes. Dr. Baryon Swain, a leading expert in Cardiothoracic and Vascular Surgery, has been at the forefront of developing 
          and refining hybrid procedures for aortic aneurysms, demonstrating his commitment to innovation and excellence in patient care.
          </div>
        </div>

        <div className={classes.imgContainer2}> 
        <img
          className={classes.HomeImageContainer2}
          src={image}
          alt="logo"
        ></img>
        </div>
      </div>
    </div>
  );
}

export default FirstBlock;
