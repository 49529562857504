import React,{useState,useEffect} from 'react';
import classes from './GeneralContentDiv.module.css';
import base from '../../CommonAppUtilities/AppContentDiv.module.css';
import {Route,Switch, useHistory} from 'react-router-dom';

import CreateDocForm from './createDocForm';

import DocumentNames from './DocumentNames';

import {getallbylawdocs, getonebylawdetail, putonebylawdetail} from '../../../CommonApps/AllAPICalls'; 

import { diffChars } from 'diff';


const TextComparator = ({ originalText, newText }) => {
  const getDifferences = () => {
    const differences = diffChars(originalText, newText);
    return differences.map((part, index) => {
      const color = part.added ? 'green' : part.removed ? 'red' : 'grey';
      return (
        <span key={index} style={{ color }}>
          {part.value}
        </span>
      );
    });
  };

  return <div>{getDifferences()}</div>;
};





const GeneralContentDiv=(props)=>{

       const history = useHistory();

       const [showForm, setShowForm] = useState(false);

       const [allDocs, setAllDocs] = useState(null);

       const [rerender,setRerender] = useState(false);

       const [byLawId, setByLawId] = useState(1);

       const [oneByLawObj, setOneByLawObj] = useState(null);


       const initialFormData = Object.freeze({
          name: "",
          description: "",
     });

     const [formData, updateFormData] = useState(initialFormData);








      const selectADocumentHandler=(bylawId)=>{

	console.log("bylawId: ", bylawId);   
	setByLawId(byLawId=> bylawId);

      }





       useEffect(()=>{

          getallbylawdocs({setAllDocs});        

       },[rerender])



     useEffect(()=>{

     let bylawId = byLawId; 	     
      bylawId !==null && getonebylawdetail({bylawId, setOneByLawObj});
  

     },[byLawId])





    console.log("oneByLawObj: ", oneByLawObj);

       const createdocHandler=()=>{

         setShowForm(showForm=>true);

       }



       const closeFormHandler=()=>{

         setShowForm(showForm=>false);
         setRerender(rerender=>!rerender);

       }



        const handleChange=(e)=>{

         updateFormData({
                        ...formData,
                        [e.target.name]: e.target.value.trim(),
                });



          }




     const [showComparedText, setShowComparedText] = useState(false);


     const compareHandler=()=>{

         setShowComparedText(showComparedText=>!showComparedText);

      }




      const referenceText = "The term apartment is favoured in North America (although in some Canadian cities, flat is used for a unit which is part of a house containing two or three units, typically one to a floor[1]). In the UK, the term apartment is more usual in professional real estate and architectural circles where otherwise the term flat is used commonly, but not exclusively, for an apartment on a single level (hence a 'flat' apartment).In some countries, the word unit is a more general term referring to both apartments and rental business suites. The word 'unit' is generally used only in the context of a specific building.";



return (

<div className={base.appContentDiv}>

	<div className={classes.contentDiv}>
     

          <button type="button" className={classes.createDocButton} onClick={createdocHandler}> 
	        Create new document  
	  </button>
  

          { showForm && 
            <CreateDocForm onPress={closeFormHandler}/>
	  }

           <div className={classes.documentArea}>
   
	     { allDocs !==null &&
               <DocumentNames  allDocs={allDocs} selectADocumentHandler={selectADocumentHandler} byLawId={byLawId}/>
             }



	       <div className={classes.documentTextArea}>	   
	          { !showComparedText &&
	          <button type="button" className={classes.saveButton} onClick={compareHandler}> Compare with ORERA By Law </button>
                  }


	         { showComparedText &&
                  <button type="button" className={classes.saveButton} onClick={compareHandler}> Turn on Edit mode </button>
                  }

	         { !showComparedText &&
                  <textarea
                     type="text"
                     onChange={handleChange}
                     className={classes.textAreaInput}
		     name="description"
                     value= {oneByLawObj !==null ? oneByLawObj.description:"N/A"}
                    />

		 }



	       { showComparedText &&

		 <div style={{width: "80%",margin: "auto"}}>	       
                   <TextComparator originalText={referenceText} newText={oneByLawObj !==null ? oneByLawObj.description:"N/A"} />
                  </div>

	       }

	       </div>




           </div>


   </div>
</div>	

);

}


export default GeneralContentDiv;
