import { useHistory } from "react-router-dom";
import classes from "./IntroductionBlock.module.css";
import bookAppointment from "./Owner.png";

import deal from "./House2.png";
import doctor from "./Dr Baryon Swain.png";
// import doctor from "./doctorImg.png"

import map from "./mp.svg";
//Through tireless advocacy, community engagement, and policy reform initiatives, I am committed to challenging the status quo and breaking down the barri//ers that hinder equitable access to housing. My mission is to uplift marginalized voices, empower vulnerable individuals, and foster a society where eve//ryone can thrive in a place they proudly call home.
{
  /*With unwavering determination, I strive to be a driving force in creating a brighter future where housing is recognized as a fundamental human right, not a mere privilege reserved for the fortunate few. I firmly believe that safe and affordable homes are the cornerstone of strong and empowered communities.
   */
}



function IntroductionBlock() {

  const history = useHistory();
  const contactPageHandler = () => {
    history.push("/contactus");
  };

  return (
    <div className={classes.IntroductionBlockParent}>
      <div className={classes.websiteContent}>
        <div className={classes.detailContainer}>
          <div className={classes.detailContainer1}>
	    {/*
            <img className={classes.textHeading1} src={deal} alt="logo"></img>
            */}
            <div className={classes.textHeading2}>
	       <span className={classes.textHeadingTitle}> Precision and Compassion:</span> Elevating Cardiac Care to New Heights with Expertise and Empathy.
            </div>
          </div>
           
	  {/*
          <img src={map} className={classes.odisaMap}></img>
           */}
          {/* <div className={classes.textDetails}>
            Welcome to the forefront of change! I am Bimalendu Pradhan, a
            dedicated RERA activist fueled by a relentless passion to stand up
            for the people and combat the pressing real estate issues that
            deeply affect our communities.With unwavering determination, I
            strive to be a driving force in creating a brighter future where
            housing is recognized as a fundamental human right, not a mere
            privilege reserved for the fortunate few. I firmly believe that safe
            and affordable homes are the cornerstone of strong and empowered
            communities.
          </div> */}
        </div>

        <div className={classes.HomeImageContainer1}>
          <img
            src={doctor}
            className={classes.HomeImageContainer}
            alt="logo"
          ></img>
          {/*
          <div className={classes.btnContainer}>
            <button
              type="button"
              className={classes.btn1}
              onClick={contactPageHandler}
            >
              Ask Questions
            </button>
            <button type="button" className={classes.btn2}>
              Register Association
            </button>

            <button type="button" className={classes.btn3}>
              Orera Register Projects
            </button>

            <button type="button" className={classes.btn4}>
              Formation Of Association
            </button>
          </div>
          */}


        </div>
      </div>
    </div>
  );
}

export default IntroductionBlock;
