import classes from "./Testimonial.module.css";
import React, { useRef, useEffect } from 'react';

import bookAppointment from "./bookAppointment.jpg";
import t1 from "./t1.jpg";
import t2 from "./t2.jpeg";
import t3 from "./t3.jpg";

import Arindam from "./Arindam.jpeg";

import { AiFillTwitterCircle } from "react-icons/ai";

function Testimonial() {

  // const divRef = useRef(null);

  // // Automatically scroll the div to the right on component mount
  // useEffect(() => {
  //   const scrollDiv = divRef.current;
  //   if (scrollDiv) {
  //     scrollDiv.scrollLeft = scrollDiv.scrollWidth - scrollDiv.clientWidth;
  //   }
  // }, []);


  return (
    <div className={classes.TestimonialParent}>
      <div className={classes.ourClientDetails}>
        
        <div className={classes.titleContainer}> 
        <div className={classes.clientTitle}>TESTIMONIALS:</div>
        <div className={classes.clientSubTitle}>What patients say..</div>
        </div>




        <div className={classes.clientFeedbackContainer} >
          
          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={Arindam} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>

            <div className={classes.clientDetails}>
            Dr. Baryon Swain's exceptional expertise in minimally invasive heart surgery saved my life.
             His precise skills and personalized approach made all the difference.
             His thorough understanding of my condition instilled confidence in me.
            </div>

          <div className={classes.patientNameDiv}> 
            <div className={classes.clientName}>Arindam Choudhury</div>

            <div className={classes.clientStatus}>
              Former Secretary (Trident Galaxy Residential Welfare Association)
            </div>
            </div>
          </div>

          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={t2} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>
            <div className={classes.clientDetails}>
            Thanks to Dr. Baryon Swain's specialized knowledge in vascular surgery, I regained mobility in my legs. 
            His expertise and attention to detail ensured a successful outcome."
            </div>


            <div className={classes.patientNameDiv}> 

            <div className={classes.clientName}>Bimalendu Pradhan</div>

            <div className={classes.clientStatus}>
              (Bhubhneswar, Odisha)
            </div>
            </div>
          </div>

          <div className={classes.aboutClinetBox}>
            <div className={classes.clientFeedback}>
              <img className={classes.clientImg} src={t3} alt="logo"></img>

              <AiFillTwitterCircle className={classes.socialNetworkImage} />
            </div>
            <div className={classes.clientDetails}>
            "Dr. Baryon Swain's mastery in complex cardiothoracic procedures gave me a new lease on life.
             His expertise and compassionate care guided me through a challenging journey to recovery."

            </div>
            <div className={classes.patientNameDiv}> 
            <div className={classes.clientName}> Pankaj Das </div>

            <div className={classes.clientStatus}>(Cuttack, Odisha)</div>
            </div>
          </div>

       

        </div>



      </div>
    </div>
  );
}

export default Testimonial;
