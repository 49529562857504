import classes from "./LeftImageRightText.module.css";
import bookAppointment from "./about1.png";
import image from "./editedDoctor (1).png";

function LeftImageRightText() {
  return (
    <div className={classes.LeftImageRightTextParent}>
      
      <div className={classes.title}>Achievements</div>

      <div className={classes.rightImgLeftTxt}>

        <div className={classes.txtDiv}>

            <div className={classes.introduction} >
              Dr. Baryon Swain is a highly esteemed Cardiothoracic and Vascular Surgeon with a track record of excellence and accomplishment in the field of healthcare. His dedication to advancing patient care and surgical innovation has garnered recognition and accolades from peers and patients alike. Some of Dr. Swain's notable achievements include:</div>
           
            <div className={classes.list}>
              <span className={classes.heading}> DNB (Post-graduation/specialization and super-specialization): </span>
              Awarded by prestigious institutions for his outstanding performance and contributions to the field of Cardiothoracic and Vascular Surgery from 2015 to 2020.</div>

            <div className={classes.list}>
            <span className={classes.heading}>   Leadership Roles:</span>
              Led the Department of Cardiothoracic and Vascular Surgery at ISPAT Post Graduation Institute & Super Speciality Hospital, Rourkela, and IQ City Medical College and Hospital, Durgapur, showcasing exceptional leadership and management skills.</div>
         
            <div className={classes.list}>
              <span className={classes.heading}> Advanced Surgical Procedures:</span>
              Successfully performed complex procedures, including minimally invasive cardiac surgery, heart transplants, and hypertrophic cardiomyopathy surgery, demonstrating proficiency and expertise in advanced surgical techniques.</div>
           
            <div className={classes.list} >
              <span className={classes.heading}> Innovative Treatments:</span>
              Pioneered innovative treatments such as the complex hybrid procedure for aortic arch aneurysm (debranching with TEVAR), contributing to advancements in the field of Cardiothoracic and Vascular Surgery.</div>
           
            <div className={classes.list}>
              <span className={classes.heading}> Publication and Research:</span>
              Authored numerous national and international publications on topics related to Cardiothoracic and Vascular Surgery, contributing to the body of knowledge and driving advancements in patient care.</div>

            <div className={classes.list}>
              <span className={classes.heading}> Conference Presentations and Workshops:</span>
              Presented research findings and surgical techniques at national conferences and workshops on Mechanical Circulatory Support (ECMO/LVAD/Impella), sharing expertise and insights with colleagues in the medical community.</div>

        </div>

        <div className={classes.imageContainer}>
        <img
          className={classes.HomeImageContainer2}
          src={image}
          alt="logo"
        ></img>
        </div>


      </div>

    </div>
  );
}

export default LeftImageRightText;
