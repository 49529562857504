import classes from "./CenterColumnLeftRightGrid.module.css";
import { useHistory } from 'react-router-dom';

import bookAppointment from "./g_affordable (copy).png";
import centerPic from "./center_img.jpg";
import SecondBlockImage from "./SecondBlockImage2.png";
import LogoWatchDog from "./g_Real_estate.png";
import Enthusiast from "./g_community_development.png";
import Defender from "./shields.png";
import Policy from "./g_policy_Reform_efforts.png";

// import Block2Image from "./Dr Baryon Swain.png";
import Block2Image from "./sample.jpeg"

import Transparency from "./g_Transperency_Crusader.png";

import { HiPresentationChartBar } from "react-icons/hi";

import imageOne from './imgOneInFour.jpg';
import imageTwo from './ImgTwoInFour.jpg';
import imageThree from './imgThreeInFour.jpg';
import imageFour from './imgFourInFour.jpg';

function CenterColumnLeftRightGrid() {
  const history = useHistory();

  const handleKnowMoreClick = () => {
    history.push('/about');
    window.scrollTo(0, 0); 
  };

  const handleKnowMoreClickTreatments = () => {
    history.push('/treatmentOptions');
    window.scrollTo(0, 0);
  }



  return (
    <div className={classes.centerImageLeftRightGrid}>

      <div className={classes.titleContainer}> 
      <div className={classes.title}>ABOUT</div>

      <div className={classes.subTitle}>Dr. Baryon Swain</div>
      </div>

      <div className={classes.details}>
        
        <div>Dr. Baryon Swain who is presently working as the Chief Consultant of Cardiothoracic and vascular surgery at IGKC Super Specialty Hospital Bhubaneswar has a stellar academic background and over eight years of clinical expertise in Cardiothoracic and Vascular Surgery, Dr. Baryon Swain stands as a distinguished consultant within the medical field. A proud alumnus of the esteemed Sir Ganga Ram Hospital, New Delhi, Dr. Baryon's journey is marked by notable achievements and contributions to the field of healthcare. From his formative years at MKCG Medical College, Berhampur, to his tenure as a junior consultant at Caritas Hospital, Kottayam, Kerala, Dr. Baryon's commitment to excellence has been unwavering. </div>
        <div>His leadership roles at renowned institutions such as ISPAT Post Graduation Institute & Super Speciality Hospital, Rourkela, and IQ City Medical College and Hospital, Durgapur, attest to his exceptional capabilities. Dr. Baryon's expertise spans a wide spectrum of vascular and endovascular repairs, alongside proficiency in complex thoracic procedures and surgeries for lung cancer. </div>
        <div>His dedication to advancing medical knowledge is evident through his active participation in national conferences and workshops, as well as his extensive publication record. Notably, Dr. Baryon's recent groundbreaking hybrid procedure for an aortic arch aneurysm showcases his pioneering spirit and commitment to pushing the boundaries of medical innovation. As an academician and practitioner, Dr. Baryon Swain continues to redefine excellence in Cardiothoracic and Vascular Surgery, leaving an indelible mark on the medical community.</div>        

          <div className={classes.knowMoreBtn}   onClick={handleKnowMoreClick}>Know more</div>
      </div>



      <div className={classes.serviceSection}>

        <div className={classes.treatmentHeading}><span className={classes.treatment}>Treatment</span>Options</div>

        
        <div className={classes.imagesTextContainer}> 
          <div className={classes.imagesContainer}>

            <div className={classes.topImg}> 
            <div className={classes.firstImgDiv}>
            <img
          className={classes.HomeImageContainer}
          src={imageOne}
          alt="logo"
        ></img>
            </div>

            <div className={classes.secondImgDiv}><img
          className={classes.HomeImageContainer}
          src={imageTwo}
          alt="logo"
        ></img></div>
        </div>


          <div className={classes.bottomImg}> 
            <div className={classes.thirdImgDiv}><img
          className={classes.HomeImageContainer}
          src={imageThree}
          alt="logo"
        ></img></div>

            <div className={classes.fourthImgDiv}> <img
          className={classes.HomeImageContainer}
          src={imageFour}
          alt="logo"
        ></img></div>
        </div>

          </div>


          <div className={classes.txtContainer}>
            <div className={classes.justifyTxt}>Dr. Baryon Swain specializes in a wide range of Cardiothoracic and Vascular surgical procedures, delivering comprehensive care tailored to each patient's unique needs. With a commitment to excellence and a patient-centered approach, Dr. Swain offers the following treatment services:</div>
          
            <div>
              <h3> Minimally Invasive Cardiac Surgery:</h3>
              <ul> 
                <li>Coronary artery bypass grafting (CABG)</li>
                <li>Valve repair and replacement</li>
                <li>Atrial fibrillation surgery</li>
                <li>Cardiac tumor removal</li>
              </ul>
            </div>

            <div>
              <h3>Vascular and Endovascular Repair:</h3>
              <ul>
                <li>Aortic aneurysm repair</li>
                <li>Carotid artery surgery</li>
                <li>Peripheral artery disease treatment</li>
                <li>Deep vein thrombosis (DVT) management</li>
              </ul>
            </div>

            <div>
              <h3>Thoracic Procedures:</h3>
              <ul> 
                <li>lobectomy and segmentectomy for lung cancer</li>
                <li>Pneumonectomy for advanced lung disease</li>
                <li>Mediastinal tumor resection</li>
                <li>Post-transplant care and management
                </li>
              </ul>
            </div>


            <div>
              <h3>Hypertrophic Cardiomyopathy Surgery:</h3>
              <ul> 
                <li>Septal myectomy</li>
                <li>Alcohol septal ablation</li>
                <li>Implantation of pacemakers or defibrillators</li>
              </ul>
            </div>

            <div></div>

          </div>

           
        </div>
        <div className={classes.knowMoreBtnoptions} onClick={handleKnowMoreClickTreatments}>Know more</div>
       
      </div>
    </div>
  );
}

export default CenterColumnLeftRightGrid;
