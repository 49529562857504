import { useEffect, useState, useRef } from "react";

import classes from "./About.module.css";

import LeftImageRightText from "./LeftImageRightText";
import RightImageLeftText from "./RightImageLeftText";

import office from "./editedDoctor (1).png";
// import office from "./editedImgAchievements.png";
// import office2 from "./office2.jpg";
import surgery from "./Aboutus2(1)-fotor-202404121642.png";
import office3 from "./about1.JPG";
import p1 from "./p1.png";
import mission from "./mission.png";
import vission from "./vision.png";

import checklist from "./checklist.png";

import doctor from "./image (4) (2).png";
import objectives from "./objective.jpg";
// import doctor from "./Dr Baryon Swain.png";

import { useHistory } from "react-router-dom";

const About = (props) => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);

  const history = useHistory();

  const contactusHandler = () => {
    history.push("/contactus");
  };

  return (
    <div className={classes.about}>
      <div className={classes.mainTitle}>About us</div>

      <h1 className={classes.topTitle}>
      Welcome to Dr. Baryon Swain's Practice
      </h1>

      <div className={classes.aboutbox}>
        <div className={classes.leftBox}>
          <img
            className={classes.OfficeImageContainer}
            src={doctor}
            alt="logo"
          ></img>
        </div>

        <div className={classes.RightBox}>
          <div className={classes.p1}>
          Dr. Baryon Swain, Chief Consultant of Cardiothoracic and Vascular Surgery at IGKC Super Specialty Hospital
           Bhubaneswar stands as a beacon of excellence in the medical field. With over eight years of clinical expertise, Dr. Swain has garnered a 
          stellar reputation for his commitment to providing cutting-edge healthcare with compassion and precision.
          </div>

          <div className={classes.p2}>
          <h2>
Academic Excellence and Clinical Expertise</h2>
A proud alumnus of the esteemed Sir Ganga Ram Hospital, New Delhi, Dr. Swain's academic journey began at MKCG Medical College, Berhampur. His formative years laid the foundation for a career marked by notable achievements and contributions to Cardiothoracic and Vascular Surgery. Dr. Swain's dedication to excellence was evident during his tenure as a junior consultant at Caritas Hospital, Kottayam, Kerala.
          </div>

          {/* <div className={classes.p3}>
            <h2>Leadership and Innovation</h2>
Dr. Baryon Swain's leadership roles at renowned institutions such as ISPAT Post Graduation Institute & Super Speciality Hospital, Rourkela, and IQ City Medical College and Hospital, Durgapur, underscore his exceptional capabilities. His expertise spans a wide spectrum of vascular and endovascular repairs, alongside proficiency in complex thoracic procedures and surgeries for lung cancer.
</div> */}

          {/* <button className={classes.getintouchParentDiv}>Get In Touch</button> */}
        </div>
      </div>

      <div className={classes.aboutbox2}>
        <div className={classes.RightBox1}>

        <div className={classes.p3}>
            <h2>Leadership and Innovation</h2>
Dr. Baryon Swain's leadership roles at renowned institutions such as ISPAT Post Graduation Institute & Super Speciality Hospital, Rourkela, and IQ City Medical College and Hospital, Durgapur, underscore his exceptional capabilities. His expertise spans a wide spectrum of vascular and endovascular repairs, alongside proficiency in complex thoracic procedures and surgeries for lung cancer.
</div>
          <div className={classes.p1}>
           <h2>Pushing the Boundaries of Medical Innovation</h2>
Dr. Swain's dedication to advancing medical knowledge is unparalleled. He actively participates in national conferences and workshops, contributing to the collective growth of the medical community. His extensive publication record reflects his commitment to sharing knowledge and fostering innovation. Notably, Dr. Swain's recent groundbreaking hybrid procedure for an aortic arch aneurysm showcases his pioneering spirit and commitment to pushing the boundaries of medical innovation.
          </div>

          <div className={classes.p2}>
       <h2> Patient-Centered Care</h2>    

At Dr. Baryon Swain's practice, patient care is paramount. Dr. Swain and his team are dedicated to providing personalized care tailored to meet the unique needs of each patient. From the moment you walk through our doors, you'll experience a warm and welcoming environment where your health and well-being are our top priorities.
          </div>

          <div className={classes.p3}>
            {/* In recognition of his unwavering commitment and exemplary efforts in
            reforming the real estate sector, the Odisha Apartment Owners
            Association (OAOA) honored Bimalendu in 2023. Positioned at the
            forefront of change, Bimalendu Pradhan's vision goes beyond legal
            victories; it is a commitment to creating a future where housing is
            a fundamental human right, and safe, affordable homes form the
            foundation of empowered communities. Welcome to the forefront of
            change led by Bimalendu Pradhan, a dedicated RERA activist. */}
          </div>

          <button className={classes.getintouchParentDiv}>Get In Touch</button>
        </div>

        <div className={classes.leftBox1}>
          <img
            className={classes.OfficeImageContainer2}
            src={surgery}
            alt="logo"
          ></img>
        </div>
      </div>

      {/* <div className={classes.whyChooseUsTitle}>Mission</div>

      <div className={classes.whyChooseUsDescription}>
        "Pioneering Progress: Transforming Real Estate through Legislative
        Innovation."
      </div> */}

      <div className={classes.whyChooseUsContainer}>
       
          <div className={classes.objectiveImgContainer}>
          <img
            className={classes.objective}
            src={objectives}
            alt="logo"
          ></img>
          </div>

          <div className={classes.objectiveContent}>

            <div className={classes.objectiveContentInner}>
              <div className={classes.titleObj}>Objective</div>
              <div className={classes.contentObj}>At Dr. Baryon Swain's practice, we aim to deliver exceptional Cardiothoracic and Vascular Surgery services with a focus on innovation, 
                compassion, and patient-centered care. We strive to uphold the highest standards of medical excellence
                 while continuously pushing the boundaries of medical innovation to improve patient outcomes and quality of life.</div>
            </div>

            <div>
            <div  className={classes.titleObj}>Mission</div>
              <div  className={classes.contentObj}>Our mission is to empower patients to lead healthier lives through comprehensive, personalized healthcare. We are committed 
                to fostering a collaborative relationship between doctor and patient, built on trust, communication, and mutual respect. Through
                 evidence-based medicine and a holistic approach to treatment,
                 we aim to provide the highest quality care tailored to meet the unique needs of each individual.</div>
            </div>

            <div>
            <div  className={classes.titleObj}>Vision </div>
              <div  className={classes.contentObj}>Our vision is to be a leader in Cardiothoracic and Vascular Surgery, recognized for our commitment to 
                excellence, innovation, and patient-centered care. We aspire to create a healthcare experience that exceeds
                 expectations, where every patient feels valued, supported, and empowered to achieve optimal health and well-being. 
                 By continuously advancing medical knowledge and embracing emerging technologies, we seek to redefine the standards of excellence
                 in our field and positively impact the lives of our patients and the broader medical community.</div>
            </div>

          </div>
      </div>

      {/* <div className={classes.whyChooseUsTitle}>Vision</div>

      <div className={classes.whyChooseUsDescription}>
        "Shaping Tomorrow: Ethical Thriving, Empowered Buyers, and Community
        Well-being – Redefining Odisha's Real Estate Landscape."
      </div> */}

      {/* <div className={classes.whyChooseUsContainer}>
        <div className={classes.block1st}>
          <div className={classes.box1}>
            <img className={classes.boxImg} src={vission} alt="logo"></img>

            <div className={classes.boxTitle}>Vision Statement</div>

            <div className={classes.boxDesc}>
            Our vision is to be a leader in Cardiothoracic and Vascular Surgery, recognized for our commitment to excellence,
             innovation, and patient-centered care. We aspire to create a healthcare experience that exceeds expectations, where every 
             patient feels valued, supported, and empowered to achieve optimal health and well-being. 
            </div>
          </div>

          <div className={classes.box1}>
            <img className={classes.boxImg} src={vission} alt="logo"></img>

            <div className={classes.boxTitle}>
              Catalyst for Positive Change:
            </div>

            <div className={classes.boxDesc}>
              Our ultimate vision is to be trailblazers and catalysts for
              positive change. Through The Odisha Apartment Ownership Act, we
              aspire to inspire nationwide reform, creating a ripple effect that
              transforms real estate governance across the nation. Our vision
              extends beyond the state, influencing a broader context of
              positive change.
            </div>
          </div>
        </div>

        <div className={classes.block2st}>
          <div className={classes.box1}>
            <img className={classes.boxImg} src={vission} alt="logo"></img>

            <div className={classes.boxTitle}>Empowering Developers:</div>

            <div className={classes.boxDesc}>
              Our vision sees a real estate sector where developers thrive
              through ethical practices, setting a new standard for industry
              conduct. The Odisha Apartment Ownership Act serves as a guiding
              force, fostering an environment where responsible development
              practices become the norm, ensuring long-term success for
              developers.
            </div>
          </div>

          <div className={classes.box1}>
            <div className={classes.blueRound}></div>
            <img className={classes.boxImg} src={vission} alt="logo"></img>

            <div className={classes.boxTitle}>Secured Rights for Buyers:</div>

            <div className={classes.boxDesc}>
              We envision a future where buyers enjoy secured rights, empowered
              by The Odisha Apartment Ownership Act. The legislation ensures
              transparency and fairness, providing buyers with a sense of
              security and confidence in their investments. This vision aims to
              redefine the buyer's experience in the real estate journey.
            </div>
          </div>
        </div>
      </div> */}

      <div className={classes.process}>
        <div className={classes.processBox1}>
          <div className={classes.processTitle}>Achievement</div>

          <div className={classes.processDesc}>
          Dr. Baryon Swain is a highly esteemed Cardiothoracic and Vascular Surgeon with a track record of 
          excellence and accomplishment in the field of healthcare. His dedication to advancing patient care and surgical innovation has garnered
           recognition and accolades from peers and patients alike. Some of Dr. Swain's notable achievements include:
          </div>

          {/* <button className={classes.buttonProcess}>Get In Touch</button> */}
          <div className={classes.container}> 
          <div className={classes.blueRound}></div>
          <img className={classes.processImage} src={office} alt="logo"></img>
          </div>
        </div>

        <div className={classes.processBox2}>
          <div className={classes.processCard}>
            <img className={classes.cardIcon} src={p1} alt="logo"></img>

            <div className={classes.processContent}>
              <div className={classes.title}>
              DNB (Post-graduation/specialization and super-specialization):
              </div>

              <div className={classes.processDesc}>
              Awarded by prestigious institutions for his outstanding performance and contributions to the field of Cardiothoracic and Vascular Surgery from 2015 to 2020.
              </div>
            </div>
          </div>

          <div className={classes.processCard}>
            <img className={classes.cardIcon} src={p1} alt="logo"></img>

            <div className={classes.processContent}>
              <div className={classes.title}>Leadership Roles:</div>

              <div className={classes.processDesc}>
               
Led the Department of Cardiothoracic and Vascular Surgery at ISPAT Post Graduation Institute & Super
 Speciality Hospital, Rourkela, and IQ City Medical College and Hospital, Durgapur, showcasing exceptional leadership and management skills.
              </div>
            </div>
          </div>

          <div className={classes.processCard}>
            <img className={classes.cardIcon} src={p1} alt="logo"></img>

            <div className={classes.processContent}>
              <div className={classes.title}>
              Advanced Surgical Procedures:
              </div>

              <div className={classes.processDesc}>
               
Successfully performed complex procedures, including minimally invasive cardiac surgery, heart transplants, and hypertrophic cardiomyopathy surgery, demonstrating proficiency and expertise in advanced surgical techniques.

              </div>
            </div>
          </div>

          <div className={classes.processCard}>
            <img className={classes.cardIcon} src={p1} alt="logo"></img>

            <div className={classes.processContent}>
              <div className={classes.title}>
              Innovative Treatments:
              </div>

              <div className={classes.processDesc}>
               
Pioneered innovative treatments such as the complex hybrid procedure for aortic arch 
aneurysm (debranching with TEVAR), contributing to advancements in the field of Cardiothoracic and Vascular Surgery.
              </div>
            </div>
          </div>

          <div className={classes.processCard}>
            <img className={classes.cardIcon} src={p1} alt="logo"></img>

            <div className={classes.processContent}>
              <div className={classes.title}>
              Publication and Research:
              </div>

              <div className={classes.processDesc}>
               
Authored numerous national and international publications on topics related to Cardiothoracic and Vascular Surgery, 
contributing to the body of knowledge and driving advancements in patient care.
              </div>
            </div>
          </div>

          {/* <div className={classes.processCard}>
            <img className={classes.cardIcon} src={p1} alt="logo"></img>

            <div className={classes.processContent}>
              <div className={classes.title}>Recognition and Legacy in Real Estate Governance</div>

              <div className={classes.processDesc}>
              In 2023, the Odisha Apartment Owners Association (OAOA) recognized Bimalendu's unwavering commitment. His tireless pursuit of justice rectified systemic issues, laying the foundation for a more transparent and accountable real estate sector in Odisha.

              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className={classes.aboutLastSection}>
        <div className={classes.lastSectionTitle1}>Thats All About Me</div>
        <div className={classes.lastSectionTitle2}>Feel Free to Say Hi</div>

        <div className={classes.lastSectiondesc1}>
          Thank you for visiting! Your support means the world to me. If you
          have any questions or inquiries, feel free to contact me. Have a great
          day!
        </div>

        <button className={classes.lastSectionBtn} onClick={contactusHandler}>
          Get In Touch
        </button>
      </div> */}
    </div>
  );
};

export default About;
